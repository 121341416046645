import { type IconProps, Icon } from './icon.js';

export function Globe(props: Omit<IconProps, 'children'>) {
  return (
    <Icon aria-label="Globe" {...props}>
      <svg viewBox="0 0 20 20">
        <path d="M19.999 9.989C19.992 4.479 15.509 0 9.999 0 4.487 0 0 4.486 0 10s4.486 10 10 10c5.51 0 9.992-4.48 9.999-9.988l.001-.011-.001-.012Zm-10 8.734c-.164 0-.328-.005-.491-.014-.68-1-1.228-2.189-1.625-3.5a19.224 19.224 0 0 1 3.903-.01c-.4 1.32-.95 2.515-1.635 3.52-.05.001-.1.004-.151.004Zm-4.128-9.36H1.302a8.679 8.679 0 0 1 1.6-4.425c1.055.43 2.19.756 3.382.973a19.78 19.78 0 0 0-.413 3.451Zm1.678-3.264c.76.087 1.537.137 2.326.137.761 0 1.51-.045 2.245-.126.223 1.04.36 2.134.397 3.252h-5.37c.038-1.122.176-2.22.402-3.263Zm-1.678 4.54c.038 1.207.181 2.388.423 3.512a17.18 17.18 0 0 0-3.357.96 8.672 8.672 0 0 1-1.635-4.472h4.57Zm1.277 0h5.369a18.393 18.393 0 0 1-.408 3.313 20.582 20.582 0 0 0-2.234-.124c-.784 0-1.558.05-2.316.136a18.406 18.406 0 0 1-.411-3.325Zm5.917 4.738c1.074.184 2.1.462 3.055.831a8.714 8.714 0 0 1-4.363 2.338 15.05 15.05 0 0 0 1.308-3.17Zm.31-1.241a19.75 19.75 0 0 0 .418-3.497h4.903a8.671 8.671 0 0 1-1.69 4.552 17.007 17.007 0 0 0-3.631-1.055Zm.418-4.774a19.722 19.722 0 0 0-.409-3.436 17.004 17.004 0 0 0 3.655-1.07 8.675 8.675 0 0 1 1.657 4.506h-4.903Zm2.374-5.525c-.964.377-2.002.66-3.09.848a15.21 15.21 0 0 0-1.318-3.23 8.714 8.714 0 0 1 4.408 2.382ZM10.153 1.28c.693 1.019 1.248 2.24 1.649 3.583a19.168 19.168 0 0 1-3.934-.012c.398-1.333.95-2.546 1.638-3.56.164-.01.328-.015.494-.015.052 0 .102.004.153.004Zm-2.294.263A15.232 15.232 0 0 0 6.593 4.67a16.093 16.093 0 0 1-2.829-.761A8.713 8.713 0 0 1 7.86 1.543ZM3.808 16.138a16.187 16.187 0 0 1 2.797-.747 15.12 15.12 0 0 0 1.254 3.066 8.72 8.72 0 0 1-4.051-2.32Z" />
      </svg>
    </Icon>
  );
}
